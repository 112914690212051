import React from 'react';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import { TData } from 'src/components/crud/Crud.d';
import { CrudHeaderProps } from 'src/components/crud/fields/CrudHeader/CrudHeader.d';

const CrudHeader = <T extends TData>(props: CrudHeaderProps<T>) => {
    const { column } = props;

    return (
        <Box>
            {column.field.separator && (
                <Divider
                    sx={{
                        mb: 4,
                    }}
                />
            )}
            <Typography
                variant={'subtitle1'}
                sx={{
                    pb: 0,
                    mb: 0,
                }}
                children={column.header}
            />
        </Box>
    );
};

export default CrudHeader;
