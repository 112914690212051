import React, { useState } from 'react';

import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import CrudText from 'src/components/crud/fields/CrudText';

import { TData } from 'src/components/crud/Crud.d';
import { CrudPasswordProps } from './CrudPassword.d';

const CrudPassword = <T extends TData>(props: CrudPasswordProps<T>) => {
    const { column, values, setValues, errors, readOnly } = props;

    const [showPassword, setShowPassword] = useState(false);

    return (
        <CrudText
            column={{
                ...column,
                field: {
                    InputProps: {
                        endAdornment: (
                            <InputAdornment position={'end'}>
                                <IconButton
                                    onMouseUp={(event) => {
                                        event.preventDefault();
                                        setShowPassword(false);
                                    }}
                                    onMouseDown={(event) => {
                                        event.preventDefault();
                                        setShowPassword(true);
                                    }}
                                >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    },
                    ...column.field,
                    type: showPassword ? 'text' : 'password',
                },
            }}
            values={values}
            setValues={setValues}
            errors={errors}
            readOnly={readOnly}
        />
    );
};

export default CrudPassword;
