import { MRT_ColumnFiltersState } from 'material-react-table';

import { FILTER_LOOKUP_EXPRS, FilterLookup } from 'src/components/crud/Crud.d';

export const getLookupFilterField = (field: string, lookup: FilterLookup): string => {
    if (lookup === 'exact') {
        return field;
    }

    return `${field}__${lookup}`;
};

export const isLookupExpr = (expr: string): boolean => {
    return expr in FILTER_LOOKUP_EXPRS;
};

export const rmLookupFromFilter = (field: string): string => {
    const parts = field.split('__');

    if (isLookupExpr(parts[parts.length - 1])) {
        return parts.slice(0, -1).join('__');
    }

    return field;
};

export const rmLookups = (filters: MRT_ColumnFiltersState): MRT_ColumnFiltersState => {
    return filters.map((filter) => ({
        id: rmLookupFromFilter(filter.id),
        value: filter.value,
    }));
};
