import React from 'react';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import AddIcon from '@mui/icons-material/Add';

import { TData } from 'src/components/crud/Crud.d';
import { CrudHeaderProps } from 'src/components/crud/CrudHeader/CrudHeader.d';

const CrudHeader = <T extends TData>(props: CrudHeaderProps<T>) => {
    const {
        // STRUCTURE
        name,

        // ACTIONS
        extraCrudActions,
        handleCreateUpdate,

        // PERMISSIONS
        enableCreate,
    } = props;

    return (
        <Stack
            justifyContent={'space-between'}
            direction={'row'}
            flexWrap={'wrap'}
            gap={1}
            sx={{
                mb: 2,
            }}
        >
            <Typography
                variant={'h4'}
                sx={{
                    mb: 0,
                }}
            >
                {name.plural}
            </Typography>
            <Stack direction={'row'} flexWrap={'wrap'} gap={1}>
                {extraCrudActions ? extraCrudActions() : null}
                {enableCreate && (
                    <Button
                        onClick={() => handleCreateUpdate(null)}
                        sx={{
                            m: 1,
                            color: '#000000',
                        }}
                    >
                        <AddIcon />
                        <Typography
                            variant={'button'}
                            sx={{
                                ml: 1,
                            }}
                        >
                            {name.singular}
                        </Typography>
                    </Button>
                )}
            </Stack>
        </Stack>
    );
};

export default CrudHeader;
