import React from 'react';

import TextField from '@mui/material/TextField';

import { TData } from 'src/components/crud/Crud.d';
import { CrudTextProps } from './CrudText.d';

const CrudText = <T extends TData>(props: CrudTextProps<T>) => {
    const { column, values, setValues, errors, readOnly = false, size = 'medium' } = props;

    return (
        <TextField
            autoComplete={'new-password'} // Forced disable of browser autocomplete
            type={column.field?.type ?? 'text'}
            label={column.header}
            name={column.accessorKey}
            value={values[column.accessorKey]}
            multiline={column.field && 'multiline' in column.field && (column.field?.multiline ?? false)}
            size={size}
            disabled={readOnly}
            onChange={(e) => {
                setValues({
                    ...values,
                    [e.target.name]: e.target.value,
                });
            }}
            error={errors?.hasOwnProperty(column.accessorKey) ?? false}
            helperText={errors ? errors[column.accessorKey] ?? '' : ''}
            InputProps={column.field?.InputProps && column.field.InputProps}
            inputProps={column.field?.inputProps && column.field?.inputProps}
        />
    );
};

export default CrudText;
