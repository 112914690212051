const hasNestedValue = (obj: any, path: string) => {
    if (!obj) {
        return false;
    }

    return path.split('.').reduce((acc, curr) => {
        return !!acc[curr];
    }, obj);
};

export default hasNestedValue;
