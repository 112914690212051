import getNestedValue from './getNestedValue';

const getOptionLabel = (option: any, key: Function | string) => {
    if (typeof key == 'function') {
        return key(option);
    }
    return getNestedValue(option, key, '');
};

export default getOptionLabel;
